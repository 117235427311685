import styled from 'styled-components';

/** ************************************************************************* */

const Footer = styled.footer`
  margin-top: 2rem;
`;

/** ************************************************************************* */

export default Footer;
