import { Link } from 'gatsby';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

/** ************************************************************************* */

const StyledHeader = styled.header`
  background: rebeccapurple;
  margin-bottom: 1.45rem;

  div:first-child {
    margin: '0 auto';
    max-width: 960px;
    padding: 1.45rem 1.0875rem;

    h1 {
      margin: 0;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }
`;

/** ************************************************************************* */

// type Props = InferProps<typeof Header.propTypes> & {
//   className: string;
// };

// interface Props extends InferProps<typeof Header.propTypes> {
//   className: string;
// }

function Header({ siteTitle }: InferProps<typeof Header.propTypes>) {
  return (
    <StyledHeader>
      <div>
        <h1>
          <Link to="/">{siteTitle}</Link>
        </h1>
      </div>
    </StyledHeader>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  siteTitle: ``,
};

/** ************************************************************************* */

export default Header;
